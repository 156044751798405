import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import { useMutation, useQuery } from "react-query";

import { LayoutWrapper, Input, Select, Checkbox } from "../components";
import { getCountries, sendEmailOtp } from "../apis";
import { regions } from "../utils";

const Register = () => {
  const countryQuery = useQuery("countries", getCountries, { retry: false });
  const { data } = countryQuery;
  const countries = data?.data?.results || [];

  const mutation = useMutation(({ email, regionDomain }) =>
    sendEmailOtp(email, regionDomain)
  );

  const [existEmail, setExistEmail] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
  });
  const watchPwd = watch("password");
  const watchCountry = watch("country");
  const watchRegion = watch("region");

  useEffect(() => {
    if (existEmail.length) {
      trigger("email");
    }
  }, [existEmail]);

  const onSubmit = async (data) => {
    if (mutation.isLoading) return;
    if (!selectCountryCheck()) return;
    if (!selectRegionCheck()) return;
    const { informNew, region, ...registerForm } = data;
    const emailForm = { email: registerForm.email };
    try {
      const res = await mutation.mutateAsync({
        email: emailForm,
        regionDomain: region.regionDomain,
      });
      navigate("/email-otp", {
        state: { ...registerForm, region },
      });
    } catch (error) {
      if (error.response?.status === 409) {
        setExistEmail([...existEmail, registerForm.email]);
      }
    }
  };

  const selectCountryCheck = () => {
    const hasCorrectCountry = countries.find(
      (country) => country.name === watchCountry?.name
    );
    if (!hasCorrectCountry) {
      setValue("country", { name: "" });
    }
    return !!hasCorrectCountry;
  };

  const selectRegionCheck = () => {
    const hasCorrectRegion = regions.find(
      (region) => region.name === watchRegion?.name
    );
    if (!hasCorrectRegion) {
      setValue("region", { name: "" });
    }
    return !!hasCorrectRegion;
  };

  return (
    <LayoutWrapper
      title={
        <span>
          Create your <span className="register__free">FREE</span> account
        </span>
      }
      subTitle={"Register now to try our free plan!"}
      question={
        <span onClick={() => navigate("/login")}>Already have an account?</span>
      }
    >
      <Input
        label="Email Address"
        placeholder="Email Address"
        {...register("email", {
          required: {
            value: true,
            message: "This field is required.",
          },
          validate: {
            alreadyUsed: (value) =>
              !existEmail.includes(value) ||
              "The email address has already been used.",
          },
        })}
        error={formState.errors?.email}
      ></Input>
      <Select
        label="Country"
        placeholder="Select your country"
        {...register("country.name", {
          required: {
            value: true,
            message: "This field is required.",
          },
        })}
        onMenuCloseNotInDom={selectCountryCheck}
        error={formState.errors?.country?.name}
      >
        {countries
          .filter((country) =>
            country.name
              .toLowerCase()
              .includes(watchCountry?.name?.toLowerCase())
          )
          .map((country) => (
            <Select.Option
              value={country.name}
              key={country.alpha2}
              onClick={() => {
                clearErrors("country");
                setValue("country", country);
              }}
            >
              {country.name}
            </Select.Option>
          ))}
      </Select>
      <Select
        label="LookOut Region"
        placeholder="Select your region"
        {...register("region.name", {
          required: {
            value: true,
            message: "This field is required.",
          },
        })}
        onMenuCloseNotInDom={selectRegionCheck}
        error={formState.errors?.region?.name}
      >
        {regions.map((region) => (
          <Select.Option
            value={region.name}
            key={region.value}
            onClick={() => {
              clearErrors("region");
              setValue("region", region);
            }}
          >
            {region.name}
          </Select.Option>
        ))}
      </Select>
      <Input
        label="Password"
        placeholder="Password"
        {...register("password", {
          required: {
            value: true,
            message: "This field is required.",
          },
        })}
        type="password"
        typeToogle={watchPwd?.length > 0}
        error={formState.errors?.password}
      ></Input>
      <Input
        label="Reference Code (optional)"
        placeholder="Reference Code "
        {...register("referenceCode")}
      ></Input>
      <Checkbox
        style={{ marginBottom: "33px" }}
        label="Inform me about new features and updates."
        {...register("informNew")}
      ></Checkbox>
      <button className="register__btn" onClick={handleSubmit(onSubmit)}>
        {mutation.isLoading ? "Submitting..." : "Sign Up"}
      </button>
      <div className="register__hint">
        By creating an account means you’re agree with our{" "}
        <span>Terms of Service</span> and <span>Privacy Policy</span>.
      </div>
    </LayoutWrapper>
  );
};

export default Register;
